<template>
  <Modal :activeModal="activeModal" @closeModal="closeModal" :widthModal="500">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        Buscar bilhete premiado
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-container fluid class="pb-0">
        <v-card-title class="modal-cadastro-titulo">
          Aponte a câmera para o QR Code do bilhete premiado
        </v-card-title>
        <v-col>
          <qrcode-stream
            :camera="camera"
            @decode="onDecode"
            @init="onInit"
            v-if="!errorNotCamera && !destroyed"
          >
            <p v-if="errorPermissaoCamera" class="msg-error-qrcode">
              {{ errorPermissaoCamera }}
            </p>
            <p v-if="errorNotCamera" class="msg-error-qrcode">
              {{ errorNotCamera }}
            </p>
            <v-container
              style="
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
              v-if="loading"
            >
              <v-progress-circular indeterminate color="primary" />
            </v-container>
          </qrcode-stream>
        </v-col>
      </v-container>

      <!-- <v-card-actions class="modal-cadastro-footer"> </v-card-actions> -->
    </v-card>
  </Modal>
</template>

<script>
// import { mapActions } from 'vuex'
import { validationMixin } from "vuelidate";
// import { required } from 'vuelidate/lib/validators'
import Events from "@/core/service/events";
import variables from "@/assets/styles/helpers/_variables.scss";
// import { errorSnackbar, successSnackbar } from '@/core/service/utils'
// import { each, size } from 'lodash'
import validacao from "./validacao";
import { QrcodeStream } from "vue-qrcode-reader";

// eslint-disable-next-line
const namespaceStore = "";

export default {
  name: "ModalJogos",
  mixins: [validationMixin],

  props: {
    activeModal: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Modal: () => import("@/views/components/modal"),
    QrcodeStream,
  },
  data: () => ({
    loading: false,
    nameRevenda: "",
    dadosJogos: [],
    errorNotCamera: "",
    errorPermissaoCamera: "",
    camera: "auto",
    destroyed: false,
  }),

  watch: {
    activeModal(val) {
      if (val) {
        this.camera = "auto";
        this.destroyed = false;
      }
    },
  },

  computed: {
    ...validacao,
    variables: () => variables,
  },
  mounted() {},
  methods: {
    closeModal() {
      // this.activeModal = false
      this.camera = "off";
      this.destroyed = true;

      this.$emit("closeModal", false);
      // this.limparItem()
      // this.$v.item.$reset()
      this.dadosJogos = [];
    },

    async onInit(promise) {
      this.loading = true;
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.errorPermissaoCamera =
            "Você precisa conceder permissão de acesso à câmera para poder ler o QRCode";
        } else if (error.name === "NotFoundError") {
          this.errorNotCamera = "Nenhuma câmera neste dispositivo";
        }
        // } else if (error.name === 'NotSupportedError') {
        //   this.errorCamera = 'ERROR: secure context required (HTTPS, localhost)'
        // } else if (error.name === 'NotReadableError') {
        //   this.errorCamera = 'ERROR: is the camera already in use?'
        // } else if (error.name === 'OverconstrainedError') {
        //   this.errorCamera = 'ERROR: installed cameras are not suitable'
        // } else if (error.name === 'StreamApiNotSupportedError') {
        //   this.errorCamera = 'ERROR: Stream API is not supported in this browser'
        // }
      } finally {
        this.loading = false;
      }
    },

    onDecode(string) {
      const parametrosDaUrl = string.split('?')[1]
      const listaDeParametros = parametrosDaUrl.split('&')

      var hash = {}
      for (var i = 0; i < listaDeParametros.length; i++) {
        var parametro = listaDeParametros[i].split('=')
        var chave = parametro[0]
        var valor = parametro[1]
        hash[chave] = valor
      }

      this.$emit("getPremioQrCode", hash.cod);
    },

    async reloadQrCode() {
      this.destroyed = true;

      await this.$nextTick();

      this.destroyed = false;

      // this.camera = 'off'
      // this.camera = 'auto'
    },
  },

  beforeDestroy() {
    Events.$off("ganhadores::modal::baixar");
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/components/formularios";
@import "~@/assets/styles/components/modal-cadastro";

.modal-cadastro-titulo {
  justify-content: center;
  text-align: center;
  line-height: 1.4;
  font-size: 14px;
}
</style>